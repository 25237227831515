import { useState, useMemo } from "react";
import { Button, Space, message } from "antd";
import moment from "moment";
import { selectOrder, getOrders, exportOrder } from "@/store/slice/order";
import { Currency, IsBoolEnum } from "@/utils/enum";
import { useList, useExportExcel } from "@/utils/hook";
import {
  rangeLimitDays,
  searchFieldsInitialValuesFormat,
} from "@/utils/format";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import JsonModal from "@/components/JsonModal";
import ListColumns, { exportColumnsRenderMap } from "./Columns";

const searchFields = {
  id__in: { type: "string", label: "ID" },
  order_no__in: { type: "string", label: "订单号" },
  trans_no__in: { type: "string", label: "第三方订单号" },
  currency: { type: "select", label: "货币类型", options: Currency },
  userid__in: { type: "string", label: "会员ID" },
  app_id__in: { type: "string", label: "AppID" },
  succeeded: {
    type: "select",
    label: "是否成功",
    options: IsBoolEnum,
    isBool: true,
  },
  approved: {
    type: "select",
    label: "审核通过",
    options: IsBoolEnum,
    isBool: true,
  },
  developer_id__in: { type: "string", label: "商户ID" },
  created__btw: {
    type: "rangeDate",
    label: "创建日期",
    initialValue: [moment().startOf("days"), moment().endOf("day")],
  },
  paid_at__btw: { type: "rangeDate", label: "支付时间" },
};

const Order = () => {
  const fieldsInitialValues = useMemo(() => {
    return searchFieldsInitialValuesFormat(searchFields);
  }, []);

  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getOrders, selectOrder, { ...fieldsInitialValues }, true);

  const handleCustomSearch = formModel => {
    if (!formModel.created__btw && !formModel.paid_at__btw) {
      message.warning("「创建时间」与「支付时间」必填其中一项");
      return;
    }
    if (formModel.created__btw) {
      if (!rangeLimitDays(62, formModel.created__btw)) {
        message.warning("「创建时间」不得超過62天");
        return;
      }
    }
    if (formModel.paid_at__btw) {
      if (!rangeLimitDays(62, formModel.paid_at__btw)) {
        message.warning("「支付时间」不得超過62天");
        return;
      }
    }
    handleSearch(formModel);
  };

  const [currentRow, setCurrentRow] = useState(null);
  const [jsonVisible, setJsonVisible] = useState(false);
  const handleJsonClick = async record => {
    setCurrentRow(record);
    setJsonVisible(true);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportOrder,
  });

  const columns = [
    ...ListColumns,
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record)}
            type="link"
            className="p-0"
          >
            json
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "order_no",
    "userid",
    "gateway_id",
    "name",
    "status",
    "device_type",
    "pay_status",
    "paid_at",
    "approval_status",
    "amount",
    "amount_paid",
    "succeeded",
    "created",
    "action",
  ];

  const payerCredExpandedRowRender = record => {
    return <div>{record}</div>;
  };
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleCustomSearch}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        setSelectedColumns={handleSelectedColumns}
        expandable={{
          rowExpandable: record => true,
          expandedRowRender: record =>
            payerCredExpandedRowRender(JSON.stringify(record.payer_cred)),
        }}
        onShowSizeChange={handleChangePage}
      />
      <JsonModal
        width={650}
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={false}
      />
    </Space>
  );
};
export default Order;
