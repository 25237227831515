const getComponent = name => {
  try {
    let cpn = require(`@/views/${name}`);
    return cpn.default();
  } catch (e) {
    console.log(e);
  }
};
const routes = [
  {
    path: "/",
    name: "Order",
    component: () => getComponent("Order"),
    exact: true,
    displayName: "支付订单",
  },
  // {
  //   path: "/OrderDetail/:id",
  //   name: "OrderDetail",
  //   component: () => getComponent("Order/Detail"),
  //   displayName: "订单明细",
  //   hidePageHeader: true,
  // },
  {
    path: "/Transfers",
    name: "Transfers",
    component: () => getComponent("Transfers"),
    displayName: "代付订单",
  },
  {
    path: "/AppAcct",
    name: "AppAcct",
    component: () => getComponent("AppAcct"),
    displayName: "商户账户",
  },
  {
    path: "/AppAcctLog",
    name: "AppAcctLog",
    component: () => getComponent("AppAcct/AppAcctLog"),
    displayName: "商户账变记录",
  },
  {
    path: "/developerDaily",
    name: "DeveloperDaily",
    component: () => getComponent("Report/DeveloperDaily"),
    displayName: "每日报表",
  },
  {
    path: "/DeveloperSubDaily",
    name: "DeveloperSubDaily",
    component: () => getComponent("Report/DeveloperSubDaily"),
    displayName: "子商户报表",
  },
  {
    path: "/AppGatewayDaily",
    name: "AppGatewayDaily",
    component: () => getComponent("Report/AppGatewayDaily"),
    displayName: "支付商户渠道报表",
  },
  {
    path: "/TransferAppGatewayDaily",
    name: "TransferAppGatewayDaily",
    component: () => getComponent("Report/TransferAppGatewayDaily"),
    displayName: "代付商户渠道报表",
  },
  {
    path: "/AppBalanceSummary",
    name: "AppBalanceSummary",
    component: () => getComponent("Report/AppBalanceSummary"),
    displayName: "商户余额汇总报表",
  },
];
export default routes;
