import { dateFormat, priceFormat } from "@/utils/format";
import { Currency, isActiveLang } from "@/utils/enum";
import Tag from "@/components/Tag";

const moneyRender = () => {
  return {
    render: (val, record) =>
      priceFormat({ val: val || 0, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  };
};

export const columns = [
  { title: "ID", dataIndex: "id", sorter: true },
  { title: "商户ID", dataIndex: "app_id", sorter: true },
  { title: "商户名称", dataIndex: "app_name", sorter: true },
  {
    title: "报表时间",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val, "YYYY-MM-DD"),
    sorter: true,
  },
  {
    title: "是否线上渠道",
    dataIndex: "is_online",
    render: val => <Tag val={val} />,
    sorter: true,
  },
  {
    title: "货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    className: "text-nowrap",
  },
  {
    title: "帐户余额",
    dataIndex: "total_balance",
    ...moneyRender(),
  },
  {
    title: "圈存余额",
    dataIndex: "total_block",
    ...moneyRender(),
  },
  {
    title: "累计订单次数",
    dataIndex: "total_times",
    sorter: true,
  },
  {
    title: "累计成功次数",
    dataIndex: "total_succeeded_times",
    sorter: true,
  },
  {
    title: "累计金额",
    dataIndex: "total_amount",
    ...moneyRender(),
  },
  {
    title: "累计成功金额",
    dataIndex: "total_succeeded_amount",
    ...moneyRender(),
  },
  {
    title: "提交代付笔数",
    dataIndex: "transfer_times",
    sorter: true,
  },
  {
    title: "成功代付笔数",
    dataIndex: "transfer_succeeded_times",
    sorter: true,
  },
  {
    title: "提交代付金额",
    dataIndex: "transfer_amount",
    ...moneyRender(),
  },
  {
    title: "成功代付金额",
    dataIndex: "transfer_succeeded_amount",
    ...moneyRender(),
  },
  {
    title: "存提差",
    dataIndex: "difference",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  },
  {
    title: "代收手续费",
    dataIndex: "order_fee",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  },
  {
    title: "代付手续费",
    dataIndex: "transfer_fee",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  },
  {
    title: "今天盈利",
    dataIndex: "profit_today",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  },
];
export const sumColumns = [
  {
    title: "订单成功次数",
    dataIndex: "order_succeeded_times",
  },
  {
    title: "订单成功金额",
    dataIndex: "order_succeeded_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "累计代理代付次数",
    dataIndex: "total_trans_times",
  },
  {
    title: "累计代理代付金額",
    dataIndex: "total_trans_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "代付成功次数",
    dataIndex: "transfer_succeeded_times",
  },
  {
    title: "代付成功金额",
    dataIndex: "transfer_succeeded_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  // transfer_succeeded_app_fee + transfer_succeeded_gateway_fee
  {
    title: "累計代付手續費",
    dataIndex: "transfer_succeeded_app_fee",
    render: (val, record) => {
      return priceFormat({
        val: val + record.transfer_succeeded_gateway_fee,
        currency: 0,
      });
    },
  },
];
export const statisticsColumns = columns.filter(
  i =>
    [
      "total_times",
      "total_succeeded_times",
      "total_trans_times",
      "transfer_succeeded_times",
    ].indexOf(i.dataIndex) !== -1,
);

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

export const exportColumnsRenderMap = {
  created: val => dateFormat(val),
  is_online: val => isActiveLang(val),
  currency: val => Currency[val] || "",
  total_balance: (val, record) => excelPriceFormat(val, record.currency),
  total_block: (val, record) => excelPriceFormat(val, record.currency),
  total_amount: (val, record) => excelPriceFormat(val, record.currency),
  total_succeeded_amount: (val, record) =>
    excelPriceFormat(val, record.currency),
  transfer_amount: (val, record) => excelPriceFormat(val, record.currency),
  transfer_succeeded_amount: (val, record) =>
    excelPriceFormat(val, record.currency),
  difference: (val, record) => excelPriceFormat(val, record.currency),
  order_fee: (val, record) => excelPriceFormat(val, record.currency),
  transfer_fee: (val, record) => excelPriceFormat(val, record.currency),
  profit_today: (val, record) => excelPriceFormat(val, record.currency),
};
