import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getTransfers = createAsyncThunk(
  "tranfser/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/developer/transfers",
      method: "get",
      params,
    });
    return res;
  },
);

export const postTransfer = async formModel => {
  const res = await request({
    url: `/api/developer/transfers`,
    method: "post",
    data: formModel,
  });
  return res;
};

// 匯出 excel
export const exportTransfer = async (params = {}) => {
  const res = await request({
    url: `/api/developer/transfers/export`,
    method: "get",
    params: { per_page: 9999, ...params },
  });
  return res;
};

export const slice = createSlice({
  name: "transfer",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
    gateways: [],
  },
  extraReducers: {
    [getTransfers.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
  },
});
export const selectTransfer = state => state.transfer;
export default slice.reducer;
