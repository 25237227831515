import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./slice/layout";
import routerTabReducer from "./slice/routerTab";
import authReducer from "./slice/auth";
import orderReducer from "./slice/order";
import transferReducer from "./slice/transfer";
import appAcctReducer from "./slice/appAcct";
import appAcctLogReducer from "./slice/appAcctLog";
import developerDailyReducer from "./slice/developerDaily";
import developerSubDailyReducer from "./slice/developerSubDaily";
import appGatewayDailyReducer from "./slice/appGatewayDaily";
import appBalanceSummaryDailyReducer from "./slice/appBalanceSummaryDaily";
import transferAppGatewayDailyReducer from "./slice/transferAppGatewayDaily";

export default configureStore({
  reducer: {
    layout: layoutReducer,
    routerTab: routerTabReducer,
    auth: authReducer,
    order: orderReducer,
    transfer: transferReducer,
    appAcct: appAcctReducer,
    appAcctLog: appAcctLogReducer,
    developerDaily: developerDailyReducer,
    developerSubDaily: developerSubDailyReducer,
    appGatewayDaily: appGatewayDailyReducer,
    appBalanceSummaryDaily: appBalanceSummaryDailyReducer,
    transferAppGatewayDaily: transferAppGatewayDailyReducer,
  },
});
