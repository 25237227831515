import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Typography, Button, Form, Input, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login, selectAuth, getOptStatus, setUser } from "@/store/slice/auth";
import OtpModal from "@/components/OtpModal";

const { Title } = Typography;
const Login = () => {
  const { user, isOtp } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [userData, setUserData] = useState(null);
  const handleUserData = data => {
    localStorage.setItem("user", JSON.stringify(data));
    dispatch(setUser(data));
  };

  const [otpVisible, setOtpVisible] = useState(false);
  const [otpData, setOtpData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleLogin = async () => {
    setLoading(true);
    const { status, data } = await login({ ...form.getFieldValue() });
    setLoading(false);
    if (status !== 200) return;
    setUserData(data);

    // opt 未綁定
    if (data.next) {
      setOtpVisible(true);
      setOtpData(data.next?.otp);
      return;
    }
    handleUserData(data);
  };
  useEffect(() => {
    dispatch(getOptStatus());
  });

  return (
    <Route path="/Login">
      {!user ? (
        <>
          <div className="login">
            <div className="login--box">
              <Title className="mb-2" level={2}>
                {process.env.REACT_APP_TITLE}
              </Title>
              <Form
                form={form}
                className="login--box--form"
                size="large"
                onFinish={handleLogin}
              >
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: "請輸入用戶名" }]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="用戶名"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "請輸入密碼" }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="密碼"
                  />
                </Form.Item>
                {isOtp && (
                  <Form.Item
                    name="opt"
                    // rules={[{ required: true, message: "請輸入二步验证码" }]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="二步验证码"
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>記住密碼</Checkbox>
                  </Form.Item>
                  <Button
                    size="small"
                    type="link"
                    className="login--box--form--psw"
                  >
                    忘記密碼
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                    loading={loading}
                  >
                    登入
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <OtpModal
            visible={otpVisible}
            otpAuthUrl={otpData.otp_auth_url}
            secret={otpData.secret}
            handleClose={() => handleUserData(userData)}
          />
        </>
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )}
    </Route>
  );
};
export default Login;
