import { useEffect } from "react";
import { Modal, Form, Input, Select } from "antd";
import { formLayout } from "@/utils/enum";
import Spin from "@/components/Spin";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
import SearchSelect from "@/components/SearchSelect";
const { Option } = Select;

const Edit = ({
  visible,
  data,
  onCancel,
  onOk,
  loading,
  title,
  fields,
  info = null,
}) => {
  const [form] = Form.useForm();
  const handleOk = async () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;
      await onOk({ ...formModel });
      form.resetFields();
    });
  };
  useEffect(() => {
    visible && form.setFieldsValue(data);
  });

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        {info}
        <Form {...formLayout} form={form}>
          {data?.id && <Form.Item label="ID">{data.id}</Form.Item>}
          {fields.map(i => {
            return i.inputType ? (
              i.inputType === "price" ? (
                <CurrencyHelpTextFormItemFactory
                  name={i.name}
                  label={i.label}
                  row={data}
                  defaultValKey={i.name}
                  key={i.name}
                  rules={
                    i.required
                      ? [{ required: true, message: `请输入${i.label}` }]
                      : []
                  }
                />
              ) : (
                <Form.Item
                  name={i.name}
                  label={i.label}
                  key={i.name}
                  rules={
                    i.required
                      ? [{ required: true, message: `请输入${i.label}` }]
                      : []
                  }
                >
                  {i.inputType === "searchSelect" ? (
                    <SearchSelect
                      action={i.action}
                      selector={i.selector}
                      searchKey="name"
                      val="id"
                      label={j => `${j.id} ${j.name}`}
                    />
                  ) : i.inputType === "select" ? (
                    i.name === "bank_name" ? (
                      <Select
                        showSearch
                        placeholder="请选择"
                        filterOption={(input, option) => {
                          return (
                            option.children
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {Object.keys(i.options).map(b => (
                          <Option value={b} key={b}>
                            {b} {i.options[b]}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Select allowClear placeholder="请选择">
                        {i.options &&
                          Object.keys(i.options).map(j => (
                            <Option value={j} key={j}>
                              {i.options[j]}
                            </Option>
                          ))}
                      </Select>
                    )
                  ) : (
                    <Input />
                  )}
                </Form.Item>
              )
            ) : (
              <Form.Item label={i.label} key={i.name}>
                {i.render ? i.render() : data?.[i.name]}
              </Form.Item>
            );
          })}
        </Form>
      </Spin>
    </Modal>
  );
};
export default Edit;
