import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getAppBalanceSummaryDaily = createAsyncThunk(
  "report/getAppBalanceSummaryDaily",
  async params => {
    const res = await request({
      url: "/api/developer/report/app/balance-summary/daily",
      method: "get",
      params,
    });
    return res;
  },
);

export const getAppBalanceSummaryDailySum = createAsyncThunk(
  "report/getAppBalanceSummaryDailySum",
  async params => {
    const res = await request({
      url: "/api/developer/report/app/balance-summary/daily/sum",
      method: "get",
      params,
    });
    return res;
  },
);

// 匯出 excel
export const exportAppBalanceSummaryDaily = async (params = {}) => {
  const res = await request({
    url: `/api/developer/report/app/balance-summary/daily/export`,
    method: "get",
    params: { per_page: 9999, ...params },
  });
  return res;
};

export const slice = createSlice({
  name: "AppBalanceSummaryDaily",
  initialState: {
    list: [],
    meta: {},
    sum: {},
  },
  extraReducers: {
    [getAppBalanceSummaryDaily.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data || [];
      state.meta = metaToPagin(data.meta);
    },
    [getAppBalanceSummaryDailySum.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.sum = data;
    },
  },
});
export const selectAppBalanceSummaryDaily = state =>
  state.appBalanceSummaryDaily;
export default slice.reducer;
