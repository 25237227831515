import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getAppGatewayDaily = createAsyncThunk(
  "report/getDaily",
  async params => {
    const res = await request({
      url: "/api/developer/report/app/gateway/daily",
      method: "get",
      params,
    });
    return res;
  },
);
export const getAppGatewayDailySum = createAsyncThunk(
  "report/getDailySum",
  async params => {
    const res = await request({
      url: "/api/developer/report/app/gateway/daily/sum",
      method: "get",
      params,
    });
    return res;
  },
);

// 匯出 excel
export const exportAppGatewayDaily = async (params = {}) => {
  const res = await request({
    url: `/api/developer/report/app/gateway/daily/export`,
    method: "get",
    params: { per_page: 9999, ...params },
  });
  return res;
};

export const slice = createSlice({
  name: "AppGatewayDaily",
  initialState: {
    list: [],
    meta: {},
    sum: {},
  },
  extraReducers: {
    [getAppGatewayDaily.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getAppGatewayDailySum.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.sum = data;
    },
  },
});
export const selectAppGatewayDaily = state => state.appGatewayDaily;
export default slice.reducer;
