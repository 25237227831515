import { dateFormat, priceFormat } from "@/utils/format";
import { CryptoAcctLogsType, AppAcctLogType, Currency } from "@/utils/enum";

export const listColumns = [
  { title: "ID", dataIndex: "id", sorter: true },
  { title: "商户名称/APP名称", dataIndex: "app_name", sorter: true },
  {
    title: "交易类型",
    dataIndex: "type",
    render: val => AppAcctLogType[val],
    sorter: true,
  },
  { title: "订单号", dataIndex: "order_no" },
  {
    title: "变动前金额",
    dataIndex: "b1",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "帐变后金额",
    dataIndex: "b2",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "变动金额",
    dataIndex: "amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  { title: "备注", dataIndex: "note" },
  {
    title: "创建日期",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "帐变金额",
    dataIndex: "order_amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "币种",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    className: "text-nowrap",
  },
  { title: "商户账户ID", dataIndex: "app_acct_id", sorter: true },
  { title: "商户用户ID", dataIndex: "app_user_id", sorter: true },
  { title: "第三方订单号", dataIndex: "trans_no" },
  { title: "客户端IP", dataIndex: "client_ip" },
  { title: "交易内容", dataIndex: "subject" },
  { title: "交易渠道，网关名称", dataIndex: "channel" },
  {
    title: "账变日志关联的对象类型",
    dataIndex: "content_type",
    render: val => CryptoAcctLogsType[val],
  },
  { title: "账变日志关联的对象ID", dataIndex: "content_id" },
  { title: "操作人ID", dataIndex: "op_user_id" },
  { title: "操作人", dataIndex: "op_username" },
  {
    title: "手续费",
    dataIndex: "fee",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "更新日期",
    dataIndex: "updated",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
];

export const defaultColumns = [
  "id",
  "app_name",
  "type",
  "order_no",
  "b1",
  "b2",
  "amount",
  "note",
  "created",
  "action",
];

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

export const exportColumnsRenderMap = {
  type: val => AppAcctLogType[val] || "",
  b1: (val, record) => excelPriceFormat(val, record.currency),
  b2: (val, record) => excelPriceFormat(val, record.currency),
  amount: (val, record) => excelPriceFormat(val, record.currency),
  order_amount: (val, record) => excelPriceFormat(val, record.currency),
  created: val => dateFormat(val),
  currency: val => Currency[val] || "",
  content_type: val => CryptoAcctLogsType[val] || "",
  fee: (val, record) => excelPriceFormat(val, record.currency),
  updated: val => dateFormat(val),
};
