import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getDeveloperSubDaily = createAsyncThunk(
  "report/getDeveloperSubDaily",
  async params => {
    const res = await request({
      url: "/api/developer/sub/report/daily",
      method: "get",
      params,
    });
    return res;
  },
);
export const getDeveloperSubDailySum = createAsyncThunk(
  "report/getDeveloperSubDailySum",
  async params => {
    const res = await request({
      url: "/api/developer/sub/report/sum",
      method: "get",
      params,
    });
    return res;
  },
);

export const slice = createSlice({
  name: "developerSubDaily",
  initialState: {
    list: [],
    meta: {},
    sum: {},
  },
  extraReducers: {
    [getDeveloperSubDaily.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getDeveloperSubDailySum.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.sum = data;
    },
  },
});
export const selectDeveloperSubDaily = state => state.developerSubDaily;
export default slice.reducer;
