import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Space } from "antd";
import {
  selectDeveloperDaily,
  getDeveloperDaily,
  getDeveloperDailySum,
  exportDeveloperDaily,
} from "@/store/slice/developerDaily";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { useList, useExportExcel, useSearchCache } from "@/utils/hook";
import { Currency, IsBoolEnum } from "@/utils/enum";
import { NormalTable } from "@/components/factory/TableFactory";
import SumTable from "@/components/SumTable";
import {
  sumColumns,
  columns,
  statisticsColumns,
  exportColumnsRenderMap,
} from "./DeveloperDailyColumn";

const searchFields = {
  id__in: { type: "string", label: "ID" },
  app_id__in: { type: "string", label: "商户ID" },
  app_name__k: { type: "string", label: "商户名称" },
  created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
  currency: { type: "select", label: "货币类型", options: Currency },
  is_online: {
    type: "select",
    label: "是否线上渠道",
    options: IsBoolEnum,
  },
};

const DeveloperDaily = () => {
  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getDeveloperDaily, selectDeveloperDaily, {}, true);
  const handleList = useCallback(
    resList =>
      resList.map(ll => {
        // 存提差
        const difference =
          ll.total_succeeded_amount - ll.transfer_succeeded_amount;
        // 代收手续费
        const order_fee = ll.total_amount_gateway_fee + ll.total_profit_amount;
        // 代付手续费
        const transfer_fee =
          ll.transfer_succeeded_app_fee + ll.transfer_succeeded_gateway_fee;
        // 今天盈利
        const profit_today = difference - order_fee - transfer_fee;
        return { ...ll, difference, order_fee, transfer_fee, profit_today };
      }),
    [],
  );

  const dispatch = useDispatch();
  const { handleGetPageSearchCache } = useSearchCache();
  const getSum = (params = {}) => {
    dispatch(
      getDeveloperDailySum({ ...handleGetPageSearchCache(), ...params }),
    );
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);

  const handleSearchClick = params => {
    handleSearch(params);
    getSum(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportDeveloperDaily,
    handleExportData: handleList,
  });

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <SumTable
        data={sum}
        labels={sumColumns}
        columnSize={{ xs: 1, sm: 3, xl: 4, xxl: 11 }}
      />
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={handleList(list)}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
        setSelectedColumns={handleSelectedColumns}
      />
    </Space>
  );
};
export default DeveloperDaily;
