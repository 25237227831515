import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Space } from "antd";
import {
  selectAppGatewayDaily,
  getAppGatewayDaily,
  getAppGatewayDailySum,
  exportAppGatewayDaily,
} from "@/store/slice/appGatewayDaily";
import { useList, useExportExcel, useSearchCache } from "@/utils/hook";
import { Currency, IsBoolEnum } from "@/utils/enum";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import SumTable from "@/components/SumTable";
import {
  sumColumns,
  columns,
  statisticsColumns,
  exportColumnsRenderMap,
} from "./AppGatewayDailyColumn";

const searchFields = {
  id__in: { type: "string", label: "ID" },
  app_id__in: { type: "string", label: "商户ID" },
  app_name__k: { type: "string", label: "商戶名称" },
  gateway_id__in: { type: "string", label: "渠道ID" },
  created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
  currency: { type: "select", label: "货币类型", options: Currency },
  is_online: { type: "select", label: "是否线上渠道", options: IsBoolEnum },
};

const AppGatewayDaily = () => {
  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getAppGatewayDaily, selectAppGatewayDaily, {}, true);
  const handleList = useCallback(
    resList =>
      resList.map(ll => {
        // 成功率
        const succeeded_rate = `${(
          (ll.total_succeeded_times / ll.total_times) *
          100
        ).toFixed(2)}%`;
        // 累计手续费
        const total_fee = ll.total_profit_amount + ll.total_amount_gateway_fee;
        // 渠道余额
        const gateway_balance = ll.total_succeeded_amount - total_fee;
        return { ...ll, succeeded_rate, total_fee, gateway_balance };
      }),
    [],
  );

  const dispatch = useDispatch();
  const { handleGetPageSearchCache } = useSearchCache();
  const getSum = (params = {}) => {
    dispatch(
      getAppGatewayDailySum({ ...handleGetPageSearchCache(), ...params }),
    );
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);

  const handleSearchClick = params => {
    handleSearch(params);
    getSum(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportAppGatewayDaily,
    handleExportData: handleList,
  });

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <SumTable data={sum} labels={sumColumns} />
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={handleList(list)}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
        setSelectedColumns={handleSelectedColumns}
      />
    </Space>
  );
};
export default AppGatewayDaily;
