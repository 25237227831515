import { useState } from "react";
import { Button, Space } from "antd";
import {
  selectAppAcctLog,
  getAppAcctLogs,
  exportAppAccLog,
} from "@/store/slice/appAcctLog";
import { useList, useExportExcel } from "@/utils/hook";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import { Currency } from "@/utils/enum";
import JsonModal from "@/components/JsonModal";
import {
  listColumns,
  defaultColumns,
  exportColumnsRenderMap,
} from "./AppAccLogColumn";

const searchFields = {
  id__in: { type: "string", label: "ID" },
  app_name__k: { type: "string", label: "商戶名称" },
  currency: { type: "select", label: "货币类型", options: Currency },
  created__btw: { type: "rangeDate", label: "创建日期" },
  funding_type: {
    type: "select",
    label: "资金类型",
    options: {
      '{"type__ne":13}': "余额",
      '{"type__eq":13}': "圈存",
    },
  },
};

const AppAcct = () => {
  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportAppAccLog,
  });

  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getAppAcctLogs, selectAppAcctLog, {}, true);

  const [currentRow, setCurrentRow] = useState(null);
  const [jsonVisible, setJsonVisible] = useState(false);
  const handleJsonClick = async row => {
    setCurrentRow(row);
    setJsonVisible(true);
  };

  const handleSearchSubmit = params => {
    handleSearch(params);
  };

  const columns = [
    ...listColumns,
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record)}
            type="link"
            className="p-0"
          >
            json
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchSubmit}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        setSelectedColumns={handleSelectedColumns}
      />
      <JsonModal
        visible={jsonVisible}
        data={currentRow}
        loading={false}
        onCancel={() => setJsonVisible(false)}
      />
    </Space>
  );
};
export default AppAcct;
