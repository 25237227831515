import { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  ContainerOutlined,
  UserOutlined,
  FileDoneOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { getRouterDisplayName } from "@/utils/format";
import { useDispatch } from "react-redux";

import { setRouterTabs } from "@/store/slice/routerTab";
const { Sider } = Layout;
const { SubMenu } = Menu;

const SidebarView = ({ routes }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(pathname);
  const handleSetRouterTab = key => {
    dispatch(setRouterTabs(key));
  };
  useEffect(() => {
    setSelectedKey(pathname);
  }, [pathname]);
  const Item = (key, icon = null) => (
    <Menu.Item key={key} icon={icon}>
      <Link to={key} onClick={() => handleSetRouterTab(pathname)}>
        {getRouterDisplayName(key, routes)}
      </Link>
    </Menu.Item>
  );

  return (
    <Sider breakpoint="lg" collapsedWidth="0" className="sidebar">
      <div className="sider-content">
        <div className="logo">{process.env.REACT_APP_TITLE}</div>
        <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
          {Item("/", <ContainerOutlined />)}
          {Item("/Transfers", <ContainerOutlined />)}
          {Item("/AppAcct", <UserOutlined />)}
          {Item("/AppAcctLog", <FileDoneOutlined />)}
          <SubMenu key="Report" icon={<BarChartOutlined />} title="报表">
            {Item("/DeveloperDaily")}
            {Item("/DeveloperSubDaily")}
            {Item("/AppGatewayDaily")}
            {Item("/TransferAppGatewayDaily")}
            {Item("/AppBalanceSummary")}
          </SubMenu>
        </Menu>
        <div className="tag">{process.env.REACT_APP_VERSION}</div>
      </div>
    </Sider>
  );
};
export default SidebarView;
