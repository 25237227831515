import { useState } from "react";
import { Button, Space, message, Alert } from "antd";
import { selectAppAcct, getAppAccts } from "@/store/slice/appAcct";
import { postTransfer } from "@/store/slice/transfer";
import { useList } from "@/utils/hook";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import EditableConfirm from "@/components/EditableConfirm";

import { Currency } from "@/utils/enum";
import { dateFormat, priceFormat } from "@/utils/format";
import JsonModal from "@/components/JsonModal";
import Banks from "@/utils/enum/bank";

const searchFields = {
  id__in: { type: "string", label: "ID" },
  currency: { type: "select", label: "货币类型", options: Currency },
  created__btw: { type: "rangeDate", label: "创建日期" },
};
const AppAcct = () => {
  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
    handleGetList,
  } = useList(getAppAccts, selectAppAcct, {}, true);

  const [currentRow, setCurrentRow] = useState(null);
  const [jsonVisible, setJsonVisible] = useState(false);
  const handleJsonClick = async row => {
    setCurrentRow(row);
    setJsonVisible(true);
  };
  const [editLoading, setEditLoading] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const fields = [
    {
      label: "币种",
      name: "currency",
      render: () => Currency[currentRow?.currency],
    },
    {
      label: "金额",
      name: "amount",
      inputType: "price",
      required: true,
    },
    {
      label: "账户",
      name: "account",
      inputType: "string",
      required: true,
    },
    {
      label: "姓名",
      name: "name",
      inputType: "string",
      required: true,
    },
    {
      label: "银行名称",
      name: "bank_name",
      inputType: "select",
      options: Banks,
      required: true,
    },
    {
      label: "支行名称",
      name: "sub_bank",
      inputType: "string",
      required: true,
    },
    {
      label: "发起提款的商户ID",
      name: "app_id",
    },
  ];
  const handleEditClick = record => {
    setCurrentRow(record);
    setEditVisible(true);
  };
  const handleEdit = async formModel => {
    setEditLoading(true);
    const { status } = await postTransfer({
      ...formModel,
      app_id: currentRow.app_id,
      app_acct_id: currentRow.id,
    });
    setEditLoading(false);
    if (status !== 200) return;
    setEditVisible(false);
    message.success(`已申请提款`);
    await handleGetList({ page: meta.current });
  };
  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    { title: "商户ID/AppID", dataIndex: "app_id", sorter: true },
    { title: "商户名称/App名称", dataIndex: "app_name", sorter: true },
    {
      title: "余额",
      dataIndex: "balance",
      render: (val, record) => priceFormat({ val, currency: record.currency }),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "圈存金额",
      dataIndex: "block_amount",
      render: (val, record) => priceFormat({ val, currency: record.currency }),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "币种",
      dataIndex: "currency",
      render: val => Currency[val] || "",
      className: "text-nowrap",
    },
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "更新日期",
      dataIndex: "updated",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record)}
            type="link"
            className="p-0"
          >
            json
          </Button>
          <Button
            size="small"
            type="text"
            className="p-0"
            onClick={() => handleEditClick(record)}
          >
            申请提款
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "app_id",
    "app_name",
    "balance",
    "block_amount",
    "currency",
    "created",
    "action",
  ];
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleSearch} />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
      />
      <JsonModal
        visible={jsonVisible}
        data={currentRow}
        loading={false}
        onCancel={() => setJsonVisible(false)}
      />
      <EditableConfirm
        title="申请提款"
        fields={fields}
        visible={editVisible}
        data={currentRow}
        onCancel={() => setEditVisible(false)}
        loading={editLoading}
        onOk={handleEdit}
        info={
          <Alert
            message="可提现USDT，当前汇率请联系客服人员"
            type="info"
            style={{ marginBottom: "12px" }}
          />
        }
      />
    </Space>
  );
};
export default AppAcct;
