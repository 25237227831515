import React, { useCallback, useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import {
  Card,
  Form,
  Row,
  Col,
  Space,
  Button,
  InputNumber,
  message,
} from "antd";
import InputFactory from "./InputFactory";
import SearchSelect from "@/components/SearchSelect";
import { formLayout } from "@/utils/enum";
import {
  searchFieldsFormat,
  priceFormat,
  rangeLimitDays,
  searchFieldsFilterInitialValues,
} from "@/utils/format";
import { useSearchCache } from "@/utils/hook";
import { useLocation } from "react-router-dom";
import moment from "moment";

/**
 * fields
 * @param {object} fields
 * @param {object} fields.name - field key
 * @param {string} fields.name.type - input type [string, select, switch, checkbox, rangeDate]
 * @param {string} fields.name.label - form label
 * @param {string} fields.name.options - when type === 'select', pass options.
 * @function handleSubmit  - 送出表單
 */
export const SearchFormFactory = ({
  fields = {},
  showExportBtn = false,
  exportLoading = false,
  handleSubmit = () => {},
  handleExport = () => {},
  otherBtn = null,
}) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});

  const handleReset = async () => {
    await form.resetFields();
    await form.setFieldsValue(initialValues);
    handleResetSearchCache({ pathname });
  };
  const { pathname } = useLocation();
  const {
    handleGetPageSearchCache,
    handleSetSearchCache,
    handleResetSearchCache,
  } = useSearchCache();
  const handleFields = useCallback(() => {
    const formModel = form.getFieldsValue();
    Object.keys(formModel).forEach(i => {
      formModel[i] === undefined && delete formModel[i];
    });
    const params = searchFieldsFormat({ fields, formModel });
    handleSetSearchCache({ pathname, formModel: params });
    return params;
  }, [fields, form, handleSetSearchCache, pathname]);

  const handleSearchClick = useCallback(() => {
    const params = handleFields();
    handleSubmit(params);
  }, [handleSubmit, handleFields]);

  const handleExportClick = useCallback(() => {
    const params = handleFields();
    if (!params.created__btw && !params.paid_at__btw) {
      message.warning("请选择时间区间");
      return;
    }
    // 下載天數不能超過 90 天
    const dateStr = params.created__btw || params.paid_at__btw;
    const isLimit = rangeLimitDays(90, dateStr);
    isLimit ? handleExport(params) : message.warning("下載量不得超過90天");
  }, [handleExport, handleFields]);

  const init = async () => {
    const fieldsInitialValues = searchFieldsFilterInitialValues(fields);
    setInitialValues(fieldsInitialValues);
    let searchCache = handleGetPageSearchCache();
    if (Object.keys(searchCache).length) {
      Object.keys(searchCache).forEach(i => {
        if (i.indexOf("__btw") !== -1) {
          searchCache[i] = searchCache[i].split("~").map(j => moment(j));
        }
        if (fields[i]?.type === "searchSelect") {
          const temp = searchCache[i].split(",");
          searchCache[i] = temp.map(item => Number(item));
        }
        // 资金类型格式處理
        if (["type__ne", "type__eq"].includes(i)) {
          searchCache["funding_type"] = `{"${i}":${searchCache[i]}}`;
        }
      });
    }
    await form.setFieldsValue({ ...fieldsInitialValues, ...searchCache });
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [pathname]);
  const valuePropName = type =>
    type === "checkbox" || type === "switch" ? "checked" : "value";
  return (
    <Card size="small">
      <Form form={form} {...formLayout}>
        <Row gutter={24}>
          {Object.keys(fields).map(i => {
            const inputFactoryProps = fields[i];
            const { optionLabel, ...rest } = inputFactoryProps;
            return (
              <Col xs={24} md={12} xl={8} key={i}>
                <Form.Item
                  name={i}
                  label={fields[i].label}
                  valuePropName={valuePropName(fields[i].type)}
                >
                  {inputFactoryProps.type === "searchSelect" ? (
                    <SearchSelect
                      {...rest}
                      searchKey={inputFactoryProps.searchKey || "name"}
                      val={inputFactoryProps.val || "id"}
                      label={optionLabel}
                    />
                  ) : (
                    <InputFactory
                      {...inputFactoryProps}
                      id={`search_${fields[i].label}`}
                    />
                  )}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        <Row justify="space-between">
          <Col span={8} className="text-left">
            <Space size="small">
              {showExportBtn && (
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handleExportClick}
                  loading={exportLoading}
                >
                  汇出
                </Button>
              )}
              {otherBtn}
            </Space>
          </Col>
          <Col span={8} className="text-right">
            <Space size="small">
              <Button onClick={handleReset}>清除</Button>
              <Button type="primary" onClick={handleSearchClick}>
                搜寻
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export const CurrencyHelpTextFormItemFactory = ({
  name,
  defaultValKey,
  label,
  row,
  min = 0,
  max = null,
  ...rest
}) => {
  const [value, setValue] = useState(null);
  const helpText = useCallback(() => {
    return `数量：${priceFormat({
      val: value || row[defaultValKey] || row[name] || 0,
      currency: row.currency,
    })}`;
    // eslint-disable-next-line
  }, [row, value]);
  const handleOnChange = val => {
    setValue(val);
    return val;
  };
  return (
    <Form.Item
      {...rest}
      name={name}
      label={label}
      validateStatus="warning"
      help={helpText()}
      getValueFromEvent={handleOnChange}
    >
      <InputNumber min={min} max={max} />
    </Form.Item>
  );
};
