import {
  OrderStatus,
  WXPayType,
  PayMethod,
  Currency,
  PayStatus,
  isSuccessLang,
  isActiveLang,
} from "@/utils/enum";
import { priceFormat, dateFormat } from "@/utils/format";
import Tag from "@/components/Tag";
const columns = [
  { title: "ID", dataIndex: "id", sorter: true },
  { title: "订单号", dataIndex: "order_no" },
  { title: "第三方订单号", dataIndex: "trans_no" },
  { title: "AppID", dataIndex: "app_id", sorter: true },
  { title: "商戶ID", dataIndex: "developer_id", sorter: true },
  { title: "会员ID", dataIndex: "userid", sorter: true },
  { title: "渠道ID", dataIndex: "gateway_id", sorter: true },
  { title: "会员姓名", dataIndex: "payer_name", width: 100 },
  { title: "帐户名称", dataIndex: "acct_name" },
  {
    title: "订单状态",
    dataIndex: "status",
    render: val => OrderStatus[val] || "",
    width: 100,
  },
  {
    title: "支付类别",
    dataIndex: "pay_type",
    render: val => WXPayType[val] || "",
    width: 100,
  },
  {
    title: "付款方式",
    dataIndex: "pay_method",
    className: "text-nowrap",
    render: val => PayMethod[val] || "",
  },
  {
    title: "付款人信息",
    dataIndex: "payer_cred",
    width: 100,
    render: val => JSON.stringify(val),
  },
  { title: "设备类型", dataIndex: "device_type", width: 100 },
  {
    title: "支付状态",
    dataIndex: "pay_status",
    render: val => PayStatus[val] || "",
    width: 100,
  },
  {
    title: "成功时间",
    dataIndex: "paid_at",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "审核状态",
    dataIndex: "approval_status",
    render: val => OrderStatus[val] || "",
  },
  {
    title: "审核人员",
    dataIndex: "approvers",
  },
  {
    title: "通知状态",
    dataIndex: "notify_status",
    render: val => OrderStatus[val] || "",
  },
  {
    title: "通知时间",
    dataIndex: "notified_at",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  { title: "IP", dataIndex: "client_ip" },
  { title: "错误代码", dataIndex: "failure_code", width: 100 },
  { title: "错误信息", dataIndex: "failure_msg", width: 100 },
  {
    title: "支付金额",
    dataIndex: "amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  },
  {
    title: "实际付款金额",
    dataIndex: "amount_paid",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  },
  {
    title: "赠送金额",
    dataIndex: "bonus",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  },
  {
    title: "随机金额",
    dataIndex: "amount_rand",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  },
  {
    title: "货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    className: "text-nowrap",
  },
  {
    title: "是否成功",
    dataIndex: "succeeded",
    render: (val, { status }) =>
      [4, 5, 6, 7, 9, 10, 13, 14, 15].includes(status) ? (
        <Tag val={val}>{isSuccessLang(val)}</Tag>
      ) : null,
  },
  {
    title: "是否付款",
    dataIndex: "paid",
    render: val => <Tag val={val} />,
  },
  {
    title: "审核通过",
    dataIndex: "approved",
    render: val => <Tag val={val} />,
  },
  {
    title: "是否加密货币",
    dataIndex: "is_crypto",
    render: val => <Tag val={val} />,
  },
  {
    title: "是否在线订单",
    dataIndex: "is_online",
    render: val => <Tag val={val} />,
  },
  {
    title: "清算成功",
    dataIndex: "settled",
    render: val => <Tag val={val} />,
  },
  {
    title: "创建日期",
    dataIndex: "created",
    render: val => dateFormat(val),
    className: "text-nowrap",
    sorter: true,
  },
  {
    title: "更新日期",
    dataIndex: "updated",
    render: val => dateFormat(val),
    className: "text-nowrap",
    sorter: true,
  },
];

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

// 定義需要 額外 Render 的欄位
export const exportColumnsRenderMap = {
  status: val => OrderStatus[val] || "",
  pay_type: val => WXPayType[val] || "",
  pay_method: val => PayMethod[val] || "",
  payer_cred: val => JSON.stringify(val),
  pay_status: val => PayStatus[val] || "",
  paid_at: val => dateFormat(val),
  approval_status: val => OrderStatus[val] || "",
  notify_status: val => OrderStatus[val] || "",
  notified_at: val => dateFormat(val),
  amount: (val, record) => excelPriceFormat(val, record.currency),
  amount_paid: (val, record) => excelPriceFormat(val, record.currency),
  bonus: (val, record) => excelPriceFormat(val, record.currency),
  amount_rand: (val, record) => excelPriceFormat(val, record.currency),
  currency: val => Currency[val] || "",
  succeeded: (val, { status }) =>
    [4, 5, 6, 7, 9, 10, 13, 14, 15].includes(status)
      ? isSuccessLang(val)
      : null,
  paid: val => isActiveLang(val),
  approved: val => isActiveLang(val),
  is_crypto: val => isActiveLang(val),
  is_online: val => isActiveLang(val),
  settled: val => isActiveLang(val),
  created: val => dateFormat(val),
  updated: val => dateFormat(val),
};

export default columns;
