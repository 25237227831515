import { dateFormat, priceFormat } from "@/utils/format";
import { Currency } from "@/utils/enum";

const moneyRender = () => {
  return {
    render: (val, record) =>
      priceFormat({ val: val || 0, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  };
};

export const columns = [
  { title: "ID", dataIndex: "id", sorter: true },
  { title: "商户ID", dataIndex: "app_id", sorter: true },
  { title: "商户名称", dataIndex: "app_name", sorter: true },
  {
    title: "货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    className: "text-nowrap",
  },
  {
    title: "报表时间",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val, "YYYY-MM-DD"),
    sorter: true,
  },
  {
    title: "昨天余额",
    dataIndex: "yesterday_balance",
    ...moneyRender(),
  },
  {
    title: "当天收款金额",
    dataIndex: "total_order_amount",
    ...moneyRender(),
  },
  {
    title: "当天收款手续费",
    dataIndex: "total_order_fee",
    ...moneyRender(),
  },
  {
    title: "当天出款金额",
    dataIndex: "total_transfer_amount",
    ...moneyRender(),
  },
  {
    title: "当天出款手续费",
    dataIndex: "total_transfer_fee",
    ...moneyRender(),
  },
  {
    title: "当天商户提现",
    dataIndex: "total_withdrawal",
    ...moneyRender(),
  },
  {
    title: "当天商户提现手续费",
    dataIndex: "total_withdrawal_fee",
    ...moneyRender(),
  },
  {
    title: "当天盈利",
    dataIndex: "current_day_total_profit",
    ...moneyRender(),
  },
  {
    title: "当前余额",
    dataIndex: "current_day_total_balance",
    ...moneyRender(),
  },
];

export const sumColumns = [
  {
    title: "累计收款金额",
    dataIndex: "total_order_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "累计收款手续费",
    dataIndex: "total_order_fee",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "累计出款金额",
    dataIndex: "total_transfer_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "累计出款手续费",
    dataIndex: "total_transfer_fee",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "累计商户提现",
    dataIndex: "total_withdrawal",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "累计商户提现手续费",
    dataIndex: "total_withdrawal_fee",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "累计盈利",
    dataIndex: "total_profit",
    render: val => priceFormat({ val, currency: 0 }),
  },
];

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

export const exportColumnsRenderMap = {
  created: val => dateFormat(val, "YYYY-MM-DD"),
  currency: val => Currency[val] || "",
  yesterday_balance: (val, record) => excelPriceFormat(val, record.currency),
  total_order_amount: (val, record) => excelPriceFormat(val, record.currency),
  total_order_fee: (val, record) => excelPriceFormat(val, record.currency),
  total_transfer_amount: (val, record) =>
    excelPriceFormat(val, record.currency),
  total_transfer_fee: (val, record) => excelPriceFormat(val, record.currency),
  total_withdrawal: (val, record) => excelPriceFormat(val, record.currency),
  total_withdrawal_fee: (val, record) => excelPriceFormat(val, record.currency),
  current_day_total_profit: (val, record) =>
    excelPriceFormat(val, record.currency),
  current_day_total_balance: (val, record) =>
    excelPriceFormat(val, record.currency),
  balance: (val, record) => excelPriceFormat(val, record.currency),
};
