import { useState, useMemo } from "react";
import { Space, Button, message } from "antd";
import moment from "moment";
import {
  selectTransfer,
  getTransfers,
  exportTransfer,
} from "@/store/slice/transfer";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { IsBoolEnum } from "@/utils/enum";
import { useList, useExportExcel } from "@/utils/hook";
import {
  rangeLimitDays,
  searchFieldsInitialValuesFormat,
  objFormateToSearchFields,
} from "@/utils/format";
import JsonModal from "@/components/JsonModal";
import { NormalTable } from "@/components/factory/TableFactory";
import { columns as ListColumns, exportColumnsRenderMap } from "./Columns";

const Transfer = ({ params }) => {
  const searchFields = useMemo(
    () => ({
      id__in: { type: "string", label: "ID" },
      order_no__in: { type: "string", label: "开发者订单号" },
      trans_no__in: { type: "string", label: "第三方订单号" },
      app_id__in: { type: "string", label: "商户ID" },
      app_user_id__in: { type: "string", label: "商户用户ID" },
      succeeded: {
        type: "select",
        label: "是否成功",
        options: IsBoolEnum,
        isBool: true,
      },
      created__btw: {
        type: "rangeDate",
        label: "创建时间",
        initialValue: [moment().startOf("days"), moment().endOf("day")],
      },
      ...objFormateToSearchFields(params),
    }),
    [params],
  );
  const fieldsInitialValues = useMemo(() => {
    return searchFieldsInitialValuesFormat(searchFields);
  }, [searchFields]);

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportTransfer,
  });

  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getTransfers, selectTransfer, { ...fieldsInitialValues }, true);

  const handleCustomSearch = formModel => {
    if (!formModel.created__btw) {
      message.warning("「创建时间」必填");
      return;
    }
    if (!rangeLimitDays(62, formModel.created__btw)) {
      message.warning("「创建时间」不得超過62天");
      return;
    }
    handleSearch(formModel);
  };

  const [jsonVisible, setJsonVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const handleJsonClick = record => {
    setCurrentRow(record);
    setJsonVisible(true);
  };
  const columns = [
    ...ListColumns,
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record)}
            type="link"
            className="p-0"
          >
            json
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "order_no",
    "app_name_cn",
    "name",
    "amount",
    "amount_paid",
    "succeeded_at",
    "gateway",
    "created",
    "succeeded",
    "status",
    "action",
  ];
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleCustomSearch}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        setSelectedColumns={handleSelectedColumns}
        onShowSizeChange={handleChangePage}
      />
      <JsonModal
        width={650}
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={false}
      />
    </Space>
  );
};
export default Transfer;
