import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getAppAcctLogs = createAsyncThunk(
  "appAcctLog/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/developer/appacctlogs",
      method: "get",
      params,
    });
    return res;
  },
);

// 匯出 excel
export const exportAppAccLog = async (params = {}) => {
  const res = await request({
    url: `/api/developer/appacctlogs/export`,
    method: "get",
    params: { per_page: 9999, ...params },
  });
  return res;
};

export const slice = createSlice({
  name: "appAcctLog",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
  },
  extraReducers: {
    [getAppAcctLogs.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
  },
});
export const selectAppAcctLog = state => state.appAcctLog;
export default slice.reducer;
