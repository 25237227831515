import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Space } from "antd";
import {
  selectDeveloperSubDaily,
  getDeveloperSubDaily,
  getDeveloperSubDailySum,
} from "@/store/slice/developerSubDaily";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { useList, useSearchCache } from "@/utils/hook";
import { dateFormat, priceFormat } from "@/utils/format";
import { Currency, IsBoolEnum } from "@/utils/enum";
import { NormalTable } from "@/components/factory/TableFactory";
import Tag from "@/components/Tag";
import SumTable from "@/components/SumTable";

const searchFields = {
  id__in: { type: "string", label: "ID" },
  app_id__in: { type: "string", label: "商户ID" },
  app_name__k: { type: "string", label: "商户名称" },
  created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
  currency: { type: "select", label: "货币类型", options: Currency },
  is_online: {
    type: "select",
    label: "是否线上渠道",
    options: IsBoolEnum,
  },
};

const DeveloperSubDaily = () => {
  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getDeveloperSubDaily, selectDeveloperSubDaily, {}, true);

  const dispatch = useDispatch();
  const { handleGetPageSearchCache } = useSearchCache();
  const getSum = (params = {}) => {
    dispatch(
      getDeveloperSubDailySum({ ...handleGetPageSearchCache(), ...params }),
    );
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);

  const handleSearchClick = params => {
    handleSearch(params);
    getSum(params);
  };

  const moneyRender = () => {
    return {
      render: (val, record) =>
        priceFormat({ val: val || 0, currency: record.currency }),
      className: "text-nowrap",
      sorter: true,
    };
  };

  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    { title: "商户ID", dataIndex: "app_id", sorter: true },
    { title: "商户名称", dataIndex: "app_name", sorter: true },
    {
      title: "报表时间",
      dataIndex: "created",
      className: "text-nowrap",
      render: val => dateFormat(val, "YYYY-MM-DD"),
      sorter: true,
    },
    {
      title: "是否线上渠道",
      dataIndex: "is_online",
      render: val => <Tag val={val} />,
      sorter: true,
    },
    {
      title: "货币类型",
      dataIndex: "currency",
      render: val => Currency[val] || "",
      className: "text-nowrap",
    },
    {
      title: "帐户余额",
      dataIndex: "total_balance",
      ...moneyRender(),
    },
    {
      title: "圈存余额",
      dataIndex: "total_block",
      ...moneyRender(),
    },
    {
      title: "累计订单次数",
      dataIndex: "total_times",
      sorter: true,
    },
    {
      title: "累计金额",
      dataIndex: "total_amount",
      ...moneyRender(),
    },
    {
      title: "累计赠金金额",
      dataIndex: "total_bonus",
      ...moneyRender(),
    },
    {
      title: "累计成功金额",
      dataIndex: "total_succeeded_amount",
      ...moneyRender(),
    },
    {
      title: "累计成功次数",
      dataIndex: "total_succeeded_times",
      sorter: true,
    },
    // {
    //   title: "扣除通道费后金额汇总",
    //   dataIndex: "total_amount_gateway",
    //   ...moneyRender(),
    // },
    // {
    //   title: "扣除商户费(商户+通道)后金额汇总",
    //   dataIndex: "total_amount_app",
    //   ...moneyRender(),
    // },
    {
      title: "累计盈利金额",
      dataIndex: "total_profit_amount",
      ...moneyRender(),
    },
    {
      title: "提交代付笔数",
      dataIndex: "transfer_times",
      sorter: true,
    },
    {
      title: "提交代付金额",
      dataIndex: "transfer_amount",
      ...moneyRender(),
    },
    {
      title: "提交代付人数",
      dataIndex: "transfer_users",
      sorter: true,
    },
    {
      title: "成功代付笔数",
      dataIndex: "transfer_succeeded_times",
      sorter: true,
    },
    {
      title: "成功代付金额",
      dataIndex: "transfer_succeeded_amount",
      ...moneyRender(),
    },
    {
      title: "成功代付人数",
      dataIndex: "transfer_succeeded_users",
      sorter: true,
    },
    {
      title: "提交存款人数",
      dataIndex: "order_users",
      sorter: true,
    },
    {
      title: "成功存款人数",
      dataIndex: "order_succeeded_users",
      sorter: true,
    },
    {
      title: "存提差",
      dataIndex: "total_succeeded_amount2",
      render: (val, record) =>
        priceFormat({
          val: record.total_succeeded_amount - record.transfer_succeeded_amount,
          currency: record.currency,
        }),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "代付订单笔数",
      dataIndex: "total_trans_times",
      sorter: true,
    },
    {
      title: "代付订单金额",
      dataIndex: "total_trans_amount",
      ...moneyRender(),
    },
    {
      title: "代付订单支出手续费",
      dataIndex: "total_trans_fee",
      ...moneyRender(),
    },
    {
      title: "代付支出分润金额",
      dataIndex: "total_order_out_profit",
      ...moneyRender(),
    },
    {
      title: "代付收入分润金额",
      dataIndex: "total_order_profit",
      ...moneyRender(),
    },
    {
      title: "代付成功支付商户手续费",
      dataIndex: "transfer_succeeded_app_fee",
      ...moneyRender(),
    },
    {
      title: "代付成功支付渠道手续费",
      dataIndex: "transfer_succeeded_gateway_fee",
      ...moneyRender(),
    },
  ];
  const sumColumns = [
    {
      title: "订单成功次数",
      dataIndex: "order_succeeded_times",
    },
    {
      title: "订单成功金额",
      dataIndex: "order_succeeded_amount",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "累计代理代付次数",
      dataIndex: "total_trans_times",
    },
    {
      title: "累计代理代付金額",
      dataIndex: "total_trans_amount",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "代付成功次数",
      dataIndex: "transfer_succeeded_times",
    },
    {
      title: "代付成功金额",
      dataIndex: "transfer_succeeded_amount",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "代付成功支付商户手续费",
      dataIndex: "transfer_succeeded_app_fee",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "代付成功支付渠道手续费",
      dataIndex: "transfer_succeeded_gateway_fee",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "总代付支出分润金额",
      dataIndex: "total_order_profit",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "总代付支出分润金额",
      dataIndex: "total_order_out_profit",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "总代付支出手续费",
      dataIndex: "total_trans_fee",
      render: val => priceFormat({ val, currency: 0 }),
    },
  ];
  const statisticsColumns = columns.filter(
    i =>
      [
        "total_times",
        "total_succeeded_times",
        "transfer_times",
        "transfer_succeeded_times",
        "transfer_users",
        "transfer_succeeded_users",
        "order_users",
        "order_succeeded_users",
        "total_trans_times",
      ].indexOf(i.dataIndex) !== -1,
  );
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
      />
      <SumTable
        data={sum}
        labels={sumColumns}
        columnSize={{ xs: 1, sm: 3, xl: 4, xxl: 11 }}
      />
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
      />
    </Space>
  );
};
export default DeveloperSubDaily;
