import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getOrders = createAsyncThunk(
  "order/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/developer/orders",
      method: "get",
      params,
    });
    return res;
  },
);

// 匯出 excel
export const exportOrder = async (params = {}) => {
  const res = await request({
    url: `/api/developer/orders/export`,
    method: "get",
    params: { per_page: 9999, ...params },
  });
  return res;
};

export const slice = createSlice({
  name: "order",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
  },
  reducers: {
    setOrders: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [getOrders.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
  },
});
export const { setOrders } = slice.actions;
export const selectOrder = state => state.order;
export default slice.reducer;
